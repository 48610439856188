import { mapActions, mapState } from "vuex"

export default {
    name: 'Employee',
    props: {
        item: Object
    },
    methods: {
        ...mapActions('employees', [
            'retrieveGenders'
        ]),
        getGender(id) {
            const gender = this.genders.find(e => e.id == id)
            return gender ? gender.name : '-'
        }
    },
    computed: {
        ...mapState('employees', [
            'genders'
        ]),
    },
    async created() {
        if (this.genders.length == 0) await this.retrieveGenders()
    }
}
