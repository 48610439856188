import api from '@/services/api'
import UserForm from '@/components/UserForm/UserForm.vue'

export default {
    name: 'Register',
    components: {
        UserForm
    },
    data() {
        return {
            user: {
                email: "",
                password: "",
                password_confirmation: "",
                company: "",
                kvk: "",
                vat: "",
                payroll_tax_number: "",
                iban: "",
                sector_id: "",
                nationality_id: 1,
                address_attributes: {
                    street: "",
                    housenumber: "",
                    housenumber_extension: "",
                    postcode: "",
                    place: "",
                    country_id: 1
                }
            }
        }
    },
    methods: {
        async createUser(user) {
            const response = await (await api.post('/users', user))

            if (response) {
                this.$notify({
                    group: 'data',
                    title: 'Succesvol geregistreerd',
                    text: `Er is een account voor ${response.company} aangemaakt`,
                });
                this.$router.replace('/inloggen').catch(()=>{})
            }
        }
    }
}
