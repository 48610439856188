import { required, email } from 'vuelidate/lib/validators'
import { mapActions, mapState } from "vuex"

export default {
    name: 'UserForm',
    props: {
        user: Object
    },
    methods: {
        ...mapActions('users', [
            'retrieveSectors'
        ]),
        submit() {
            this.$v.$touch()

            if (this.$v.$invalid) return
            this.$emit('onSubmit', this.user)
        }
    },
    computed: {
        ...mapState('users', [
            'sectors'
        ]),
    },
    async created() {
        if (this.sectors.length == 0) await this.retrieveSectors()
    },
    validations: {
        user: {
            email: {
                required,
                email
            },
            password: {
                required
            },
            password_confirmation: {
                required
            },
            company: {
                required
            },
            kvk: {
                required,
            },
            vat: {
                required
            },
            payroll_tax_number: {
                required
            },
            iban: {
                required
            },
            nationality_id: {
                required
            },
            sector_id: {
                required
            },
            address_attributes: {
                housenumber: {
                    required
                },
                housenumber_extension: {},
                street: {
                    required
                },
                place: {
                    required
                },
                postcode: {
                    required
                }
            }
        }
    },
}
