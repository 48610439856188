import api from '@/services/api'

const state = () => ({
    all: [],
    loading: false,
    error: false,
})

const mutations = {
    setAll(state, all) {
        console.log(all)
        state.all = all
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
}

const actions = {
    async retrieveAll({commit}, employeeId) {
        commit('setLoading', true)
        commit('setError', false)

        const response = await api.get(`/contracts/${employeeId}.json`)
        commit('setLoading', false) 

        if (response) {
            commit('setAll', response)
        } else {
            commit('setError', true)
        }
    },
}

const getters = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}