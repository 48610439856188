import { mapActions, mapState } from "vuex"

export default {
    name: 'EmployeeDashboard',
    data() {
        return {
            search: ""
        }
    },
    computed: {
        ...mapState('employees', [
            'all',
            'loading',
            'error',
            'total'
        ]),
    },
    methods: {
        ...mapActions('employees', [
            'retrieveAll'
        ]),
        detail(item) {
            this.$router.push({name: "Employee Detail", params: {id: item.id}})
        },
        newItem() {
            this.$router.push({name: "Employee New"})
        }
    },
    created() {
        this.retrieveAll()
    }
}
