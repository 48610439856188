import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from '../views/auth/Authentication/Authentication.vue'
import Register from '../views/auth/Register/Register.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'

import Pay from '../views/Pay/Pay.vue'
import PayDashboard from '../views/Pay/PayDashboard/PayDashboard.vue'

import Hours from '../views/Hours/Hours.vue'
import HoursDashboard from '../views/Hours/HoursDashboard/HoursDashboard.vue'
import HoursImport from '../views/Hours/HoursImport/HoursImport.vue'
import HoursDetail from '../views/Hours/HoursDetail/HoursDetail.vue'

import Employee from '../views/Employee/Employee.vue'
import EmployeeDashboard from '../views/Employee/EmployeeDashboard/EmployeeDashboard.vue'
import EmployeeNew from '../views/Employee/EmployeeNew/EmployeeNew.vue'
import EmployeeDetail from '../views/Employee/EmployeeDetail/EmployeeDetail.vue'
import EmployeeEdit from '../views/Employee/EmployeeEdit/EmployeeEdit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/inloggen',
    name: 'Authentication',
    component: Authentication
  },
  {
    path: '/registreren',
    name: 'Register',
    component: Register
  },
  {
    path: '/verlonen',
    component: Pay,
    children: [
      {
        path: '',
        name: 'Pay Dashboard',
        component: PayDashboard
      },
    ]
  },
  {
    path: '/uren',
    component: Hours,
    children: [
      {
        path: '',
        name: 'Hours Dashboard',
        component: HoursDashboard
      },
      {
        path: 'importeren',
        name: 'Hours Import',
        component: HoursImport
      },
      {
        path: 'werknemer/:id',
        name: 'Hours Detail',
        component: HoursDetail
      },
    ]
  },
  {
    path: '/werknemers',
    component: Employee,
    children: [
      {
        path: '',
        name: 'Employee Dashboard',
        component: EmployeeDashboard
      },
      {
        path: 'nieuw',
        name: 'Employee New',
        component: EmployeeNew
      },
      {
        path: ':id',
        name: 'Employee Detail',
        component: EmployeeDetail
      },
      {
        path: 'aanpassen/:id',
        name: 'Employee Edit',
        component: EmployeeEdit
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// Only check whether there is a jwt token, not if it's valid. This is done when making actual calls to the API.
// But this step prevents making calls to the API when there is not even a token, only when a route has the requiresAuth tag inside the meta object.
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/inloggen",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
