import { render, staticRenderFns } from "./EmployeeNew.vue?vue&type=template&id=7efe57e8&scoped=true&"
import script from "./EmployeeNew.js?vue&type=script&lang=js&"
export * from "./EmployeeNew.js?vue&type=script&lang=js&"
import style0 from "./EmployeeNew.scss?vue&type=style&index=0&id=7efe57e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7efe57e8",
  null
  
)

export default component.exports