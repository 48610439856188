import { mapActions } from "vuex"
import { required, email } from 'vuelidate/lib/validators'
import api from '@/services/api'

export default {
    name: 'Authentication',
    data() {
        return {
            user: {
                email: "",
                password: ""
            }
        }
    },
    methods: {
        ...mapActions("authentication", [
            'signinSuccessful',
            'signinFailed'
        ]),
        async signIn(data) {
            this.$v.$touch()
            if (this.$v.$invalid) return

            const response = await (await api.post('/login', data))
            if (response && response.token) {
                this.signinSuccessful(response)
            } else {
                this.signinFailed()
            }
        },
    },
    validations: {
        user: {
            email: {
                required,
                email
            },
            password: {
                required
            }
        }
    }
}
