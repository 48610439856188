import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import { BootstrapVue } from 'bootstrap-vue'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'

import 'material-design-icons';
import 'material-design-icons/iconfont/material-icons.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(Notifications)
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
