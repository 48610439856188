import { required, requiredUnless } from 'vuelidate/lib/validators'

export default {
    name: 'ContractForm',
    data() {
        return {
            contractTypes: ["Onbepaalde tijd", "Bepaalde tijd"],
            booleans: {"false": "Uit", "true": "Aan"},
        }
    },
    props: {
        contract: Object
    },
    methods: {
        submit() {
            this.$v.$touch()

            if (this.$v.$invalid) return
            this.$emit('onSubmit', this.contract)
        }
    },
    validations: {
        contract: {
            start_date: {
                required
            },
            end_date: {
                required: requiredUnless(vm => {
                    return vm.contract_type == "Onbepaalde tijd"
                }),
                minValue(val, {start_date}) {
                    if (!val) return true
                    return new Date(val) > new Date(start_date) 
                }
            },
            title: {
                required
            },
            contract_hours: {},
            contract_type: {
                required
            },
            payroll_tax: {
                required
            },
            gross_hourly_wage: {
                required
            },
            holidays_per_year: {
                required
            },
            holiday_allowance_percentage: {
                required
            },
        }
    },
}
