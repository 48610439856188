import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './modules/authentication'
import employees from './modules/employees'
import contracts from './modules/contracts'
import users from './modules/users'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    authentication,
    employees,
    contracts,
    users,
  },
});