import api from '@/services/api'

export default {
    name: 'HoursUpload',
    props: {
        shifts: Array
    },
    data() {
        return {
            isUploading: true
        }
    },
    methods: {
        preventNav(event) {
          if (!this.isUploading) return
          event.preventDefault()
          event.returnValue = ""
        },
        async startUploading() {
            this.isUploading = true;

            for (var index in this.shifts) {
                const shift = this.shifts[index]
                const response = await this.uploadShift(shift)

                const row = document.getElementById(`shift-${index}`);
                if (response) {
                    row.classList.add("good");
                    const employee = response.employee
                    shift.bsn = `${employee.first_name ? `${employee.first_name} ` : " "}${employee.insertion ? `${employee.insertion} ` : " "}${employee.last_name ? `${employee.last_name} ` : " "}`
                } else {
                    row.classList.add("bad");
                }
            }
        },
        async uploadShift(shift) {
            return await api.post('/shifts.json', shift)
        }
    },
    created() {
        this.startUploading()
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
        this.$once("hook:beforeDestroy", () => {
          window.removeEventListener("beforeunload", this.preventNav);
        })
    },
    beforeRouteLeave(to, from, next) {
        if (this.isUploading) {
            if (!window.confirm("Het uploaden zal stoppen, weet je dit zeker?")) {
            return;
            }
        }
        next();
    },
}
