import api from '@/services/api'

export default {
    name: 'HoursDashboard',
    data() {
        return {
            workedHours: {},
            employees: [],
            month: "",
            year: "",
            months: {
                1: "Januari",
                2: "Februari",
                3: "Maart",
                4: "April",
                5: "Mei",
                6: "Juni",
                7: "Juli",
                8: "Augustus",
                9: "September",
                10: "Oktober",
                11: "November",
                12: "December",
            },
            years: []
        }
    },
    methods: {
        importHours() {
            this.$router.push({name: "Hours Import"})
        },
        detail(employeeId) {
            this.$router.push({name: "Hours Detail", params: {id: employeeId}})
        },
        async retrieveWorkedHours() {
            const response = await api.post('/shifts_by_month', {
                month: parseInt(this.month),
                year: this.year
            })

            if (response) {
                this.workedHours = response
            }
        },
        async retrieveEmployees() {
            const response = await api.get('/employees_name')

            if (response) {
                this.employees = response
            }
        },
    },
    async created() {
        const date = new Date()
        this.month = date.getMonth() + 1
        this.year = date.getFullYear()
        this.years = [this.year + 1, this.year, this.year - 1]

        await this.retrieveEmployees()
        this.retrieveWorkedHours()
    }
}
