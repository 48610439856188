export default {
    name: 'PayDashboard',
    data() {
        return {
            currentMonth: "",
            year: "",
            months: {
                1: "Januari",
                2: "Februari",
                3: "Maart",
                4: "April",
                5: "Mei",
                6: "Juni",
                7: "Juli",
                8: "Augustus",
                9: "September",
                10: "Oktober",
                11: "November",
                12: "December",
            },
            years: [],
            payments: [
                
            ]
        }
    },
    methods: {
        pay(monthNumber) {
            console.log(monthNumber)
        },
        retrievePayments() {
            console.log(this.year)
        }
    },
    created() {
        const date = new Date()
        this.currentMonth = date.getMonth() + 1
        this.year = date.getFullYear()

        this.years = [this.year + 1, this.year, this.year - 1]
    }
}
