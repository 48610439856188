import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ShiftForm',
    props: {
        shift: Object
    },
    methods: {
        submit() {
            this.$v.$touch()

            if (this.$v.$invalid) return
            this.$emit('onSubmit', this.shift)
        },
        removeShift() {
            this.$emit('onRemove', this.shift)
        }
    },
    validations: {
        shift: {
            start: {
                required,
            },
            stop: {
                required
            },
        }
    }
}
