import api from '@/services/api'
import { mapActions, mapState } from "vuex"
import EmployeeForm from '@/components/EmployeeForm/EmployeeForm.vue'

export default {
    name: 'EmployeeEdit',
    components: {
        EmployeeForm
    },
    computed: {
        ...mapState('employees', [
            'item',
            'loading',
            'error',
        ]),
    },
    methods: {
        ...mapActions('employees', [
            'retrieveItem'
        ]),
        async updateEmployee(employee) {
            const response = await api.put(`/employee/${employee.id}.json`, employee)
 
            if (response) {
                this.$router.push({name: "Employee Detail", params: {id: response.id}})
            }
        }
    },
    created() {
        this.retrieveItem(this.$route.params.id)
    }
}
