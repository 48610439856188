import api from '@/services/api'

const state = () => ({
    all: [],
    genders: [],
    item: {},
    loading: false,
    error: false,

    total: 0,
    page: 0,
    size: 40
})

const mutations = {
    setAll(state, all) {
        state.all = all
    },
    setGenders(state, genders) {
        state.genders = genders
    },
    setItem(state, item) {
        item.address_attributes = item.address
        state.item = item
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    setTotal(state, total) {
        state.total = total
    },
    setPage(state, page) {
        state.page = page
    },
}

const actions = {
    async retrieveAll({commit, state}, payload) {
        const search = (payload&&payload.search) ? payload.search : ""
        const page = (payload&&payload.addedPage) ? state.page + 1 : 0

        if (page != 0) commit('setLoading', true)
        commit('setError', false)

        commit('setPage', page)

        const response = await api.post('/employees.json', {
            search: search,
            page: state.page,
            size: state.size
        })

        commit('setLoading', false)

        if (response && response.data) {
            const all = state.page > 0 ? state.all.concat(response.data) : response.data

            commit('setAll', all)
            commit('setTotal', response.total)
        } else {
            commit('setError', true)
        }
    },
    async retrieveItem({commit}, id) {
        commit('setLoading', true)
        commit('setError', false)

        const response = await api.get(`/employee/${id}.json`)

        commit('setLoading', false)

        if (response) {
            commit('setItem', response)
        } else {
            commit('setError', true)
        }
    },
    async retrieveGenders({state, commit}) {
        if (state.genders.length > 0) return;

        const response = await api.get(`/genders.json`)
        if (response) {
            commit('setGenders', response)
        } 
    }
}

const getters = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}