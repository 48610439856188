import api from '@/services/api'

const state = () => ({
    sectors: [],
})

const mutations = {
    setSectors(state, sectors) {
        state.sectors = sectors
    },
}

const actions = {
    async retrieveSectors({state, commit}) {
        if (state.sectors.length > 0) return;

        const response = await api.get(`/sectors.json`)
        if (response) {
            commit('setSectors', response)
        } 
    }
}

const getters = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}