import api from '@/services/api'
import router from '../../router'

const state = () => ({
})

const mutations = {
}

const actions = {
    
    signinSuccessful({}, response) {
        localStorage.setItem('jwt', response.token)
        router.replace('/').catch(()=>{})
    },
    signinFailed() {
        localStorage.removeItem('jwt')
    },
    signOut() {
        localStorage.removeItem('jwt')
        router.replace('/inloggen').catch(()=>{})
    },
}

const getters = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}