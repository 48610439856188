import api from '@/services/api'
import { mapActions, mapState } from "vuex"
import ShiftForm from '@/components/ShiftForm/ShiftForm.vue'

export default {
    name: 'HoursDetail',
    components: {
        ShiftForm
    },
    data() {
        return {
            month: "",
            year: "",
            months: {
                1: "Januari",
                2: "Februari",
                3: "Maart",
                4: "April",
                5: "Mei",
                6: "Juni",
                7: "Juli",
                8: "Augustus",
                9: "September",
                10: "Oktober",
                11: "November",
                12: "December",
            },
            years: [],
            shifts: [],
            shift: {},
            navigation: "overview",
            defaultShift: {
                bsn: "",
                start: "",
                stop: ""
            }
        }
    },
    computed: {
        ...mapState('employees', [
            'item',
            'loading',
            'error',
        ]),
    },
    methods: {
        ...mapActions('employees', [
            'retrieveItem',
        ]),
        async retrieveShifts() {
            const response = await api.post(`/shifts_by_employee/${this.$route.params.id}`, {
                month: parseInt(this.month),
                year: this.year
            })

            if (response) {
                this.shifts = response
            }
        },
        edit(shift) {
            this.shift = shift;
            this.navigation = 'edit';
        },
        async updateShift(shift) {
            const response = await api.put(`/shifts/${shift.id}.json`, shift)
 
            if (response) {
                this.retrieveShifts()
                this.navigation = 'overview'
            }
        },
        async createShift(shift) {
            const response = await api.post('/shifts.json', shift)
 
            if (response) {
                this.retrieveShifts()
                this.navigation = 'overview'
            }
        },
        async deleteShift(shift) {
            const response = await api.delete(`/shifts/${shift.id}.json`)

            if (response) {
                this.retrieveShifts()
                this.navigation = 'overview'
            }
        }
    },
    async created() {
        const date = new Date()
        this.month = date.getMonth() + 1
        this.year = date.getFullYear()
        this.years = [this.year + 1, this.year, this.year - 1]
        await this.retrieveItem(this.$route.params.id)
        this.defaultShift.bsn = this.item.bsn
        this.retrieveShifts()
    },
    filters: {
        datetime(val) {
            const date = new Date(val)
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        }
    }
}
