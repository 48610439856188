import { required, email } from 'vuelidate/lib/validators'
import { mapActions, mapState } from "vuex"

export default {
    name: 'EmployeeForm',
    props: {
        employee: Object
    },
    methods: {
        ...mapActions('employees', [
            'retrieveGenders'
        ]),
        submit() {
            this.$v.$touch()

            if (this.$v.$invalid) return
            this.$emit('onSubmit', this.employee)
        }
    },
    computed: {
        ...mapState('employees', [
            'genders'
        ]),
    },
    created() {
        if (this.genders.length == 0) this.retrieveGenders()
    },
    validations: {
        employee: {
            first_name: {
                required
            },
            insertion: {},
            last_name: {
                required
            },
            birth_date: {
                required
            },
            email: {
                required,
                email
            },
            phone: {
                required
            },
            bsn: {
                required
            },
            iban: {
                required
            },
            account_holder: {
                required
            },
            gender_id: {
                required
            },
            address_attributes: {
                housenumber: {
                    required
                },
                housenumber_extension: {},
                street: {
                    required
                },
                place: {
                    required
                },
                postcode: {
                    required
                }
            }
        }
    },
}
