import { mapActions, mapState } from "vuex"
import api from '@/services/api'

import Employee from '@/components/Employee/Employee.vue'
import EmployeeForm from '@/components/EmployeeForm/EmployeeForm.vue'
import Contract from '@/components/Contract/Contract.vue'
import ContractForm from '@/components/ContractForm/ContractForm.vue'

export default {
    name: 'EmployeeDetail',
    components: {
        Employee,
        EmployeeForm,
        Contract,
        ContractForm
    },
    data() {
        return {
            navigations: ['Werknemer', 'Dienstverband'],
            contractNavigations: ['Overzicht', 'Toevoegen', 'Aanpassen', 'Geschiedenis'],
            employeeNavigations: ['Overzicht', 'Aanpassen'],
            active: '',
            contractActive: '',
            employeeActive: '',
            defaultContract: {
                contract_type: "Bepaalde tijd",
                start_date: "",
                end_date: "",
                title: "",
                contract_hours: "0",
                payroll_tax: "true",
                gross_hourly_wage: "0.00",
                holidays_per_year: "30",
                holiday_allowance_percentage: "11",
            }
        }
    },
    computed: {
        ...mapState('employees', [
            'item',
            'loading',
            'error',
        ]),
        ...mapState('contracts', {
            contracts: 'all',
        }),
    },
    methods: {
        ...mapActions('employees', [
            'retrieveItem',
        ]),
        ...mapActions('contracts', {
            retrieveContracts: 'retrieveAll',
        }),
        async createContract(contract) {
            contract.employee_id = this.item.id
            const response = await api.post('/contracts.json', contract)
 
            if (response) {
                await this.retrieveItem(this.$route.params.id)
                this.contractActive = 'Overzicht'
            }
        },
        async updateContract(contract) {
            contract.employee_id = this.item.id
            const response = await api.put(`/contracts/${contract.id}.json`, contract)
 
            if (response) {
                await this.retrieveItem(this.$route.params.id)
                this.contractActive = 'Overzicht'
            }
        },
        async updateEmployee(employee) {
            const response = await api.put(`/employee/${employee.id}.json`, employee)
 
            if (response) {
                this.employeeActive = 'Overzicht'
            }
        }
    },
    async created() {
        this.active = this.navigations[0]
        this.contractActive = this.contractNavigations[0]
        this.employeeActive = this.employeeNavigations[0]
        await this.retrieveItem(this.$route.params.id)
        this.retrieveContracts(this.item.id)
    }
}
