import api from '@/services/api'
import EmployeeForm from '@/components/EmployeeForm/EmployeeForm.vue'

export default {
    name: 'EmployeeNew',
    components: {
        EmployeeForm
    },
    data() {
        return {
            employee: {
                first_name: "",
                insertion: "",
                last_name: "",
                birth_date: "",
                email: "",
                phone: "",
                bsn: "",
                iban: "",
                account_holder: "",
                nationality_id: 1,
                address_attributes: {
                    housenumber: "",
                    housenumber_extension: "",
                    street: "", 
                    postcode: "", 
                    place: "", 
                    country_id: 1
                },
                gender_id: 1,
            }
        }
    },
    methods: {
        async createEmployee(employee) {
            const response = await api.post('/employee.json', employee)
 
            if (response) {
                this.$router.push({name: "Employee Dashboard"})
            }
        }
    },
}
