import Vue from "vue"
import router from '../router'

export default {
    get(url) {
        return this.apiCall(url, 'GET')
    },
    
    post(url, data, tokenRefresh=true) {
        return this.apiCall(url, 'POST', data, tokenRefresh)
    },

    put(url, data) {
        return this.apiCall(url, 'PUT', data)
    },

    delete(url, data) {
        return this.apiCall(url, 'DELETE', data)
    },

    url() {
        return process.env.NODE_ENV == 'development' ? 'http://localhost:3000' : 'https://api.loonsimpel.nl'
    },

    async apiCall(url, method, body) {
        var headers = new Headers();
        headers.append("Authorization", localStorage.getItem('jwt'));
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        
        try {
            const response = await fetch(this.url() + url, {
                method: method,
                credentials: "include",
                headers: headers,
                body: JSON.stringify(body)
            })

            const data = await response.json()
            
            if (response.status == 401) {
                // Vue.notify({
                //     group: 'data',
                //     title: 'Unauthorized',
                //     text: `${response.error}`,
                //     type: 'error'
                // });
                localStorage.removeItem('jwt')
                router.replace('/inloggen').catch(()=>{})
            } else if (response.status >= 400) {
                if (response.status == 422) {
                    for (const key in data) {
                        if (Object.hasOwnProperty.call(data, key)) {
                            const value = data[key];
                            Vue.notify({
                                group: 'data',
                                title: `Validatie fout in ${key}`,
                                text: `${value}`,
                                type: 'error'
                            });
                        }
                    }
                } else if (data.error) {
                    Vue.notify({
                        group: 'data',
                        title: 'Fout opgetreden',
                        text: `${data.error}`,
                        type: 'error'
                    });
                } else {
                    Vue.notify({
                        group: 'data',
                        title: 'Fout',
                        text: `Er is een fout opgetreden`,
                        type: 'error'
                    });
                }
            } else {
                return data
            }
        } catch (error) {
            Vue.notify({
                group: 'data',
                title: 'Fout opgetreden',
                text: `${error}`,
                type: 'error'
            });
        }
    }
}