import Papa from 'papaparse';
import HoursUpload from '@/components/HoursUpload/HoursUpload.vue';

export default {
    name: 'HoursImport',
    components: {
        HoursUpload
    },
    data() {
        return {
            file: '',
            content: [],
            uploading: false
        }
    },
    methods: {
        submit() {
            if (!this.file || !this.content) {
                alert("Upload een csv met data")
                return
            }
            this.uploading = true
        },
        handleUpload(event) {
            this.file = event.target.files[0];
            this.parseFile();
        },
        parseFile(){
            this.content = []
            Papa.parse( this.file, {
                header: true,
                skipEmptyLines: true,
                complete: function( results ){
                    this.content = results;
                }.bind(this)
            } );
        },
    },
}
