import { mapActions } from "vuex"

export default {
    name: 'Header',
    methods: {
        ...mapActions("authentication", [
            'signOut'
        ])
    }
}
